.dialog {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow: hidden;
  display: flex;
}

.dialog_left {
  width: 20%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  &_title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #4d4d4d;
    padding: 20px 30px;
  }

  &_save {
    background-color: #000000;
    width: 220px;
    height: 40px;
    border-radius: 4px;
    color: #ffffff;
    outline: none;
    border: none;
    margin: 20px 30px;

    &:hover {
      cursor: pointer;
      opacity: 0.3;
      transition: 0.3s all;
    }
  }
}

.dialog_right {
  width: 100%;
  height: 100vh;
}

.line2 {
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
}

.image_container {
  width: 100%;
  border: 1px solid #dee4e8;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  padding: 6px;

  button {
    outline: none;
    width: 24px;
    height: 24px;
    border: 1px solid #dee4e8;
    border-radius: 4px;
    background-color: #ffff;
    position: absolute;
    right: 0;
    bottom: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .popover {
    position: absolute;
    bottom: -50px;
    right: 0;
    width: 162px;
    height: 40px;
    background: #fff1f1;
    border: 1px solid #dee4e8;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      cursor: pointer;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.25px;
      color: #ee4c3b;
      margin-left: 10px;
    }
  }
}

.loadingdiv {
  width: 250px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dee4e8;
  border-radius: 4px;
  height: 36px;

  p {
    font-family: 'Reader', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #5d676b;
    inline-size: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  button {
    outline: none;
    width: 24px;
    height: 24px;
    border: 1px solid #dee4e8;
    border-radius: 4px;
    background-color: #ffff;

    &:hover {
      cursor: pointer;
    }

    margin-right: 6px;
  }
}
