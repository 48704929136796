.eventinfo_wrapper {
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  padding-bottom: 150px;
}

.eventinfo_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .eventinfo_top_left {
    display: flex;
    align-items: center;
    .p {
      font-family: 'Mulish', sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #b9bfc6;
      text-decoration: none;
    }
  }
  .eventinfo_top_right {
    display: flex;
    align-items: center;
    position: relative;
  }
}

.pause {
  display: flex;
  align-items: center;
  position: relative;
  font-family: Reader, sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  width: 101px;
  height: 40px;
  background: #f0f2f4;
  outline: none;
  border: 1px solid #dee4e8;
  border-radius: 4px;
  letter-spacing: 1px;
  color: #9fa6ad;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
    margin: 0px 7px;
  }
  transition: 0.3s all;
  &:hover {
    opacity: 0.7;
  }
  &:hover ~ .pauseerror {
    display: inline-block;
  }
}

.preview {
  width: 115px;
  height: 40px;
  border: 1px solid var(--primarycolor);
  color: var(--primarycolor);
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  background: none;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    opacity: 0.7;
  }
}

.launch {
  color: #ffffff;
  font-family: Reader, sans-serif;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0px 15px;
  width: 115px;
  height: 40px;
  background: var(--primarycolor);
  outline: none;
  border: 1px solid var(--primarycolor);
  border-radius: 4px;
  transition: 0.3s all;
  cursor: pointer;
  &:hover ~ .launcherror {
    display: inline-block;
  }
  &:hover {
    background-color: #1a3330;
  }
}
.launcherror {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
}
.disabledlaunch {
  color: #9fa6ad;
  font-family: Reader, sans-serif;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0px 15px;
  width: 115px;
  height: 40px;
  background-color: #f0f2f4;
  outline: none;
  border: 1px solid #dee4e8;
  border-radius: 4px;
  position: relative;
  &:hover ~ .launcherror {
    display: inline-block;
  }
}

.launch_disable {
  color: #9fa6ad;
  font-family: Reader, sans-serif;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0px 15px;
  width: 115px;
  height: 40px;
  background: #f0f2f4;
  outline: none;
  border: 1px solid #dee4e8;
  transition: 0.3s all;
  cursor: pointer;
  &:hover ~ .launcherror {
    display: inline-block;
  }
  &:hover {
    opacity: 0.7;
  }
}

#orange {
  color: var(--primarycolor);
}

.pauseerror {
  display: none;
  position: absolute;
  right: 340px;
  top: 180px;
}

.launcherror {
  display: none;
  position: absolute;
  top: 50px;
  left: 90px;
}

.eventinfo_middle {
  padding: 0px;
  margin-top: 60px;
  width: 100%;
  ul {
    list-style: none;
    display: flex;
  }
}

.svg {
  fill: #9fa6ad;
}

.campaignLink {
  display: flex;
  align-items: center;
  border-bottom: 2px solid red;
  justify-content: center;
  width: 400px;
  height: 80px;
  &:hover {
    color: var(--primarycolor);
  }
  &:hover .svg {
    fill: var(--primarycolor);
  }
  svg {
    width: 32px;
    height: 32px;
    margin: 0px 10px;
  }
  border: 1px solid #eff3f6;
  cursor: pointer;
  color: #9fa6ad;
}

.campaignLink_active {
  border-bottom: 2px solid var(--primarycolor);
}

.filter_green {
  color: var(--primarycolor);
}

.eventName {
  font-family: Reader, sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #000000;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  inline-size: 130px;
}
