.customize_wrapper {
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  position: relative;
  padding-bottom: 230px;
}

.customize_flex_column {
  margin-left: 22px
}

.acordion_about {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #b9bfc6;
  margin-top: 10px;
}

.thanks {
  margin-top: 20px;
}

.line_thanks {
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background-color: #dee4e8;
}

.reward_div {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px 0px;

  &_p {
    font-family: Reader, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #22262b;
  }

  &::after {
    content: '';
    height: 4px;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: 1px solid red;
    position: absolute;
  }
}

.acordion_heading {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #42454b;
}

.bd_top {
  border-top: 4px solid var(--primarycolor);
  padding: 0;
}

.bd_top_gray {
  border-top: 1px solid #b9bfc6;
  padding: 0;
}

.done {
  font-family: Reader, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
  border-radius: 4px;
  background: none;
  width: 80px;
  cursor: pointer;
  height: 40px;
  transition: 0.3s all;

  &:hover {
    opacity: 0.7;
  }
}

.instant {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #6c737a;
  inline-size: 300px;
}

.summary {
  border-top: 1px solid red;
}

.toggle-switch-email {
  position: relative;
  width: 60px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;

  &-checkbox {
    display: none;
  }

  &-label {
    display: block;
    overflow: hidden;
    border-radius: 4px;
    cursor: pointer;
    border: 0 solid #bbb;
    margin: 0;
  }

  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;

    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 12px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }

    &:before {
      content: 'ON';
      text-transform: uppercase;
      padding-left: 10px;
      background-color: var(--primarycolor);
      color: #fff;
    }
  }

  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;

    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }

  &-inner:after {
    content: 'OFF';
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }

  &-switch {
    display: block;
    width: 14px;
    height: 14px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 5px;
    bottom: 0;
    right: 0px;
    border: 0 solid #bbb;
    transition: all 0.3s ease-in 0s;
  }

  &-checkbox:checked+&-label {
    .toggle-switch-inner {
      margin-left: 0;
    }

    .toggle-switch-switch {
      right: 0px;
    }
  }
}

.edit_email {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--primarycolor);
  text-decoration: none;
  background: none;
  outline: none;
  margin-right: 20px;
  border: none;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.7;
  }
}

.add_incentive {
  background: none;
  width: 273px;
  height: 40px;
  background-color: var(--primarycolor);
  font-family: Reader, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border: none;
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: 0.3s all;

  & img {
    margin-right: 10px;
  }

  cursor: pointer;

  &:hover {
    background-color: #1a3330;
  }
}

.close {
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.7;
  }
}

.modal_p {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #22262b;
  margin-left: 180px;
}

.logo_div_second {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}

.profile_container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button_save {
  background-color: var(--primarycolor);
  cursor: pointer;
  outline: none;
  transition: 0.3s all;

  &:hover {
    opacity: 0.7;
  }

  padding: 10px 30px;
  font-family: Reader,
  sans-serif;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  border: none;
}

.file-card {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  border-radius: 10px;
  width: 480px;
  border: 2px dashed #b9bfc6;
  background-color: #f1f1f1;

  .file-inputs {
    position: relative;

    input {
      position: relative;
      text-align: right;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
      height: 46px;
      max-width: 200px;
    }

    button {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 1.1rem;
      cursor: pointer;
      border-radius: 4px;
      border: none;
      outline: none;
      transition: 0.3s all;
    }

    &:hover {
      button {
        opacity: 0.7;
      }
    }
  }
}

.upload {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6c737a;
}

.form_incentive {
  margin-top: 30px;
  width: 100%;
}

.info {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
  color: #838b97;
}

.inc_input {
  border: 1px solid #dee4e8;
  border-radius: 4px;
  width: 476px;
  height: 48px;
  margin: 10px 0px;
  outline: none;
  padding: 10px;

  &::placeholder {
    font-family: Reader, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #9fa6ad;
  }
}

.point {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #9fa6ad;
}

.btn_save {
  width: 175px;
  height: 40px;
  font-family: Reader, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  background-color: var(--primarycolor);
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
  transition: 0.3s all;

  &:hover {
    background-color: #1a3330;
  }
}

.share_event_btn {
  background-color: #dee4e8;
  width: 176px;
  height: 40px;
  text-decoration: none;
  border-radius: 4px;
  font-family: 'Lato', sans-serif;
  border: 1px solid #dee4e8;
  font-weight: 500;
  font-size: 14px;
  color: #9fa6ad;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  outline: none;
}

.brand_logo {
  height: auto;
  width: auto;
  max-width: 255px;
  max-height: 110px;
}

.email_input {
  width: 476px;
  height: 123px;
  border-radius: 10px;
  border: 1px solid #9fa6ad;
  margin: 10px 0px;
  text-align: start;
  outline: none;
  resize: none;
  padding: 10px;
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #22262b;
}

#user_name {
  color: var(--primarycolor);
}

.email_p {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 11px;
  color: #22262b;
  line-height: 16px;

  span {
    font-family: Reader, sans-serif;
    font-size: 12px;
    color: #22262b;
    font-weight: 800;
  }
}

.bold {
  font-family: Readerbold, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #22262b;
}

#sharing_link {
  color: var(--primarycolor);
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.subject {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 20px;
  color: #42454b;
}

.subject_input {
  border: 1px solid #9fa6ad;
  border-radius: 10px;
  width: 100%;
  height: 46px;
  outline: none;
  margin-top: 10px;
  padding: 10px;
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #22262b;

  &::placeholder {
    font-family: Reader, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #9fa6ad;
  }
}

.accordionhover:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: 0.4s all;
}

.customize_square {
  width: 190px;
  height: 185px;
  border: 1px solid #eff3f6;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  img {
    margin-top: 33px;
  }

  .customize_btn {
    background-color: #1d4842;
    color: #ffffff;
    width: 100%;

    &:hover {
      opacity: 0.7;
      transition: 0.3s all;
    }
  }
}