.reward_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 158px;
  background-color: #fff;
  box-shadow: 0px 0px 30px rgba(9, 118, 66, 0.05);
  border-radius: 10px;
  padding:0 10px;
  margin-top: 13px;

  &_left {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0px;
  }
  &_right {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.reward_right {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 100%;
}

.reward_img {
  border: 1px solid #dee4e8;
  width: 79px;
  height: 79px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  img {
    width: 59px;
    height: 59px;
  }
}

.reward_name {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: #22262b;
  margin-bottom: 10px;
  word-break: break-all;
}

.reward_desc {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #6c737a;
  word-break: break-all;
}

.star {
  margin: 0px 10px;
}

.grayed {
  opacity: 0.6;
}

.empty_reward_image {
  width: 59px;
  height: 59px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
