@font-face {
  font-family: Reader;
  src: url(../../assets/fonts/reader-regular.otf);
}

.share_wrapper {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  overflow-y: scroll;
  position: relative;
  z-index: 1000;
  display: flex;
  justify-content: center;
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.reward_p_share {
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  inline-size: 100px;
  margin-top: 10px;
}

.banner {
  width: 100%;
  border-radius: 15px;
  display: flex;
  justify-content: center;


  &_left {
    width: 100%;
    padding-bottom: 10px;
    height: 614px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0px 0px 30px rgba(9, 118, 66, 0.05);

    img {
      width: 100%;
      max-width: 100%;
      height: 277px;
      border-radius: 10px 10px 0px 0px;
    }

    &_invite {
      font-family: Reader, sans-serif;
      font-weight: 600;
      font-size: 24px;
      margin: 10px 0px;
      margin-top: 50px;
    }

    &_choose {
      font-family: 'Reader', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-top: 15px;
      text-align: center;
    }

    &_buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
  }

  &_right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    width: 100%;
    height: 614px;

    &_score {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-around;
      background-color: #ffffff;
      box-shadow: 0px 0px 30px rgba(9, 118, 66, 0.05);
      border-radius: 10px;

      &_left {
        width: 100%;
        border-radius: 10px;
        padding: 20px;

        &_headline {
          font-family: 'Reader', sans-serif;
          font-style: normal;
          letter-spacing: 1px;
          font-weight: 700;
          font-size: 24px;
        }

        &_track {
          font-family: 'Reader', sans-serif;
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 0.4px;
          margin-top: 10px;
          line-height: 25px;
        }
      }

      &_right {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.isphoto {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


.reward_banner {
  width: 100%;
  background-color: transparent;
  border-radius: 15px;
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.rewards {
  font-family: 'Reader', sans-serif;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 1px;
  color: #22262b;
}

.rewards_small {
  font-family: 'Reader', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  margin-top: 10px;
}

.rewardstop {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 30px rgba(9, 118, 66, 0.05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  margin-top: 20px;
}

.content_share {
  word-break: break-all;
  overflow: hidden;
}

.noborder {
  border: none;
}

.share_image {
  width: 376px;
  height: 214px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.url_share_page {
  width: 376px;
  height: 214px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  &_bottom {
    width: 100%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px;
    padding-left: 10px;
    background-color: #eff3f6;

    & > div {
      margin: 5px 0px;
    }
  }
}

.url_text_share {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #9fa6ad;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  inline-size: 350px;
}

.url_text {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  inline-size: 450px;
}

.flower_numbers {
  font-family: Reader, sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #22262b;
}

.event_name_share {
  font-family: Reader, sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  word-break: break-all;
  line-height: 30px;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.share_logo {
  img {
    width: 245px !important;
    height: auto !important;
  }
}

.gmailsubject {
  margin-top: 30px;
}

.host_name {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-top: 10px;
}

.break {
  word-break: break-all;
}

.disclamer_div {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 30px rgba(9, 118, 66, 0.05);
  padding: 10px;

  & > img {
    width: 15, 5px;
    height: 15, 5px;
  }

  & > p {
    font-family: 'Reader', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6c737a;
    margin-left: 10px;
  }
}

.react-share__ShareButton--disabled {
  background: #f0f2f4;
  /* Gray/Gray 200 */
  border: 1px solid red;
  border-radius: 15px;
}

.linkedin-share,
.facebook-share,
.email-share,
.twitter-share {
  &:hover {
    opacity: 0.8;
  }

  transition: 0.3s all;
}

.nohover {
  &:hover {
    background: #f0f2f4 !important;
  }
}

.emptystate {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-family: 'Reader';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #34343f;
    margin-top: 22px;
  }
  span {
    font-family: 'Reader';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #22262b;
    margin-top: 14px;
  }
}

.share_wrapper_overlay {
  position: relative;
}
