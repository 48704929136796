.confirm {
  width: 128px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  border: 1px solid var(--primarycolor);
  transition: 0.3s all;
  background: none;
  background-color: var(--primarycolor);
  font-family: Reader, sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.empty_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
  h2 {
    font-family: 'Reader';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #42454b;
    margin-top: 15px;
  }
  p {
    font-family: 'Reader';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #6c737a;
    margin-top: 15px;
  }
}

.close_x {
  cursor: pointer;
  &hover {
    opacity: 0.7;
  }
}

.sessions {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #42454b;
  display: flex;
  flex-direction: column;
}
.cancel {
  width: 128px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  border: 1px solid #dee4e8;
  transition: 0.3s all;
  background: none;
  font-family: Reader, sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #2c3336;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.select_count {
  padding: 5px;
  margin-top: 30px;
  border: 1px solid #dee4e8;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    opacity: 0.7;
  }
}

.archived {
  opacity: 0.4;
}

.tabletd {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #42454b;
}

.tableth {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #929292;
}

.table {
  margin-top: 8px;
}

.user_table_name {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #42454b;
  margin-bottom: 5px;
}

.user_table_email {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #42454b;
}

td {
  width: 100px;
}

.user_table {
  display: flex;
  align-items: center;
}

.user_table_info {
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  margin: 0px 10px;
  color: #5d676b;
}

.user_table_img {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #eff3f6;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  p {
    font-family: Reader, sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    text-transform: uppercase;
  }
}

.pagination {
  margin-left: 500px;
  margin-top: 30px;
}
.arrowimg {
  transition: 0.3s all;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
.copy_snackbar {
  animation: show-snackbar 0.3s forwards;
  position: absolute;
  top: 20px;
  right: 0px;
  width: 400px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #ffff;
  font-size: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  .success_icon {
    margin-left: 10px;
  }
  p {
    margin-right: 165px;
    font-family: Reader, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #2c3336;
  }
}

@keyframes show-snackbar {
  0% {
    right: 0;
    opacity: 0;
  }
  50% {
    right: 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-70px);
  }
}

.refering_source {
  font-family: 'Reader', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.25px;
  color: #42454b;
}
