.linkedin_wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border: 1px solid #b9bfc6;
  border-radius: 10px;
  display: flex;
}

.linkedin_left {
  width: 400px;
  border-right: 1px solid #b9bfc6;
  padding: 25px;
  overflow-y: scroll;
  height: 550px;
}
.linkedin_right {
  width: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 25px;
}

.textarea {
  width: 415px;
  height: 123px;
  border: 1px solid #9fa6ad;
  border-radius: 10px;
  padding: 10px;
  margin: 0px 0px;
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #42454b;
}

.content {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #42454b;
  margin-top: 10px;
  margin-bottom: 5px;
}

.preview_text {
  margin-top: 10px;
  margin-bottom: 10px;
  inline-size: 100%;
  margin-left: 5px;
  word-break: break-all;
}

.input_subject {
  width: 415px;
  height: 40px;
  outline: none;
  border: 1px solid #9fa6ad;
  border-radius: 7px;
  padding: 10px;
}

.body {
  margin-top: 10px;
}

.upload_div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.close_image {
  background: none;
  border: none;
  transition: 0.3s all;
  cursor: pointer;
  margin-left: 15px;

  &:hover {
    opacity: 0.7;
  }
}
