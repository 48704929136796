.eventslist_wrapper {
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  padding-bottom: 150px;
}

.eventslist_events {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}
.expired {
  width: 100%;
  height: 48px;
  background-color: #fff1f1;
  border-radius: 4px;
  border: 1px solid #da1e28;
  margin-top: 33px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    margin-left: 16px;
  }
  p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #2c3336;
    margin-left: 8px;
    a {
      color: #ee4c3b;
      text-decoration: none;
    }
  }
}

.event {
  width: 100%;
  margin: 11px 0px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  height: 131px;
  padding: 0px 25px;
  justify-content: space-between;
  border: 1px solid #eff3f6;
  transition: 0.3s all;
  .standard {
    font-family: Reader, sans-serif;
    font-weight: 400;
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 16px;
    color: #9fa6ad;
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
  }
}

.event_name {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #22262b;
  margin-left: 10px;
}

.event_right {
  width: 15%;
  height: 100%;
  border-left: 1px solid #eff3f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.session {
  font-family: Reader, sans-serif;
  font-weight: 400;
  margin-top: 15px;
  font-size: 13px;
  color: #9fa6ad;
}

.disabledmanage {
  width: 115px;
  height: 40px;
  margin-left: 20px;
  background-color: #fff;
  font-family: Reader, sans-serif;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  color: var(--primarycolor);
  transition: 0.3s all;
  cursor: inherit;
  outline: none;
  border: 1px solid var(--primarycolor);
}

.manage {
  width: 115px;
  height: 40px;
  margin-left: 20px;
  background-color: #fff;
  font-family: Reader, sans-serif;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  color: var(--primarycolor);
  transition: 0.3s all;
  cursor: pointer;
  outline: none;
  border: 1px solid var(--primarycolor);
  &:hover {
    opacity: 0.7;
  }
}

.setup {
  width: 115px;
  height: 40px;
  margin-left: 20px;
  background-color: var(--primarycolor);
  font-family: Reader, sans-serif;
  font-weight: 500;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  transition: 0.3s all;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    opacity: 0.7;
  }
}

.setupdisabled {
  width: 115px;
  height: 40px;
  margin-left: 20px;
  background-color: var(--primarycolor);
  font-family: Reader, sans-serif;
  font-weight: 500;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  transition: 0.3s all;
  cursor: inherit;
  outline: none;
  border: none;
}

.marginTop30 {
  margin-top: 30px;
}

.events {
  font-family: Reader, sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}

.top {
  width: 100%;
  padding: 20px 0px;
}

.eventLink {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin: 0px 15px;
  color: #9fa6ad;
  cursor: pointer;
  height: 150px;
}

.list_navlink {
  padding: 0px 0px;
  display: flex;
  color: #5d676b;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  font-family: Reader, sans-serif;
  font-weight: 400;
  height: 90px;
  cursor: pointer;
  font-size: 15px;
  margin-left: 30px;
}

.activelist {
  color: var(--primarycolor);
  border-bottom: 2px solid var(--primarycolor);
}

.demio_line {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  background: #edf5ff;
  justify-content: flex-start;
  border: 1px solid #0043ce;
  border-radius: 4px;
  margin-top: 31px;
  p {
    margin-left: 10px;
    font-family: Reader;
    font-weight: 400;
    font-size: 14px;
  }
  img {
    width: 16px;
    height: 16px;
    margin-left: 16px;
  }
}

.expired {
  width: 100%;
  height: 48px;
  background-color: #fff1f1;
  border-radius: 4px;
  border: 1px solid #da1e28;
  margin-top: 33px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    margin-left: 16px;
  }
  p {
    font-family: 'Reader', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #2c3336;
    margin-left: 8px;
    a {
      color: #ee4c3b;
      text-decoration: none;
    }
  }
}
