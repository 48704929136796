.templates_wrapper {
  width: 100%;
  margin-top: 30px;
}
.left {
  width: 30%;
  border-right: 1px solid #b9bfc6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 600px;
  button {
    margin: 20px 0px;
    transition: 0.3s all;
    background: none;
    display: flex;
    width: 180px;
    align-items: center;
    padding-left: 30px;
    border: none;
    outline: none;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    img {
      cursor: pointer;
      width: 28px;
      height: 28px;
      transition: 0.3s all;
      margin-right: 10px;
      opacity: 0.4;
    }
  }
}

#activeIcon {
  opacity: 1;
  &:hover {
    opacity: 0.2;
  }
}

.right {
  width: 80%;
  padding: 20px;
  height: 600px;
}

.edit_p {
  font-family: Reader, sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #22262b;
}

.templateicon {
  width: 40px;
}

.toggle-switch {
  transform: scale(0.81);
  position: relative;
  width: 52px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    border-radius: 4px;
    cursor: pointer;
    border: 0 solid #bbb;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 25px;
      padding: 0;
      line-height: 28px;
      font-size: 12px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: 'ON';
      text-transform: uppercase;
      padding-left: 6px;

      padding-bottom: 6px !important;
      background-color: var(--primarycolor);
      color: #fff;
    }
  }
  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: 'OFF';
    text-transform: uppercase;
    padding-right: 5px;
    background-color: #bbb;
    color: #5d676b;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 19px;
    height: 18px;
    margin: 3px;
    background: #fff;
    position: absolute;
    top: 1px;
    bottom: 0;
    right: 28px;
    border: 0 solid #bbb;
    border-radius: 3px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
}

.urltitle {
  inline-size: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.twitter_preview_text {
  p {
    overflow: hidden;
    font-family: Reader, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #42454b;
    margin-top: 20px;
    word-break: break-all;
  }
}
