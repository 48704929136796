.promoters {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #5d676b;
  margin-left: 50px;
}

.audience_wrapper{
    width: 100%;
    margin: 0 auto;
    min-height: 100%;
    padding-bottom: 220px;
}

.input-icons img {
  position: absolute;
  right: 10px;
  top: 5px;
  padding: 10px;
  min-width: 40px;
}

.input-icons {
  position: relative;
}
.input-field {
  width: 261px;
  height: 48px;
  padding: 10px;
  outline: none;
  border: 1px solid #dee4e8;
  border-radius: 4px;
  &::placeholder {
    font-family: Reader,sans-serif;
    font-style: normal;
    font-size: 14px;
    color: #9fa6ad;
  }
}
