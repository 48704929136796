.green {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #02bf6f;
}

.yellow {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #f1c21b;
}

.event_name {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #22262b;
  margin-left: 10px;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  inline-size: 450px;
}

.event_right {
  width: 15%;
  height: 100%;
  border-left: 1px solid #eff3f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eventdisabled {
  width: 100%;
  margin: 11px 0px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  height: 131px;
  padding: 0px 25px;
  justify-content: space-between;
  border: 1px solid #eff3f6;
  opacity: 0.6;
  transition: 0.3s all;
  .standard {
    font-family: Reader, sans-serif;
    font-weight: 400;
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 16px;
    color: #9fa6ad;
  }
  &:hover {
    cursor: inherit;
  }
}
