* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Reader', sans-serif;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Reader;
  src: url(../assets/fonts/reader-regular.otf);
}

@font-face {
  font-family: Readerbold;
  src: url(../assets/fonts/reader-bold.otf);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
 }

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9f9;
  border-radius: 100px;
}


/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(66, 69, 75, 0.5);
  border-radius: 100px;
}

mark {
  background-color: #a3beba;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

body {
  font-family: 'Reader', sans-serif;
  font-size: 16px;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

:root {
  --primarycolor: #1d4842;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

.flex_center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}

.flex_end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex_start {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex_between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.margin {
  margin: 15px 0px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #eff3f6;
}

.error {
  color: #ff0000;
  font-size: 12px;
  margin-top: 5px;
}

.green {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #02bf6f;
}

.grey {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #9fa6ad;
}

table {
  width: 100%;
  color: #465282;
  text-align: start;
}

td,
th {
  padding: 10px;
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 20px;
  text-align: start;
  color: #42454b;
  border-bottom: 1px solid #eff3f6;
}

.templates_14px {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #42454b;
}

.templates_16px {
  font-family: 'Reader', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #22262b;
}
