// TODO: Fix Class Naming Convention 
.email_icon {
  width: '36px';
  height: '36px'
}

.customize_flex_column {
  margin-left: '30px'
}

.reminder-description {
  font-family: Lato, sans-serif;
  font-size: 16px;
  color: #5D676B;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 40px;
}

.modal-main {
  width: 100%;
  padding: 24px;
}

.modal-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #2C3336;
}

.reminder-message {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #2C3336;
}

.switch {
  display: 'flex';
  align-items: 'center'
}

.reminder-save {
  width: 175px;
  height: 40px;
  font-family: Reader, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  background-color: var(--primarycolor);
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  border: none;
  transition: 0.3s all;
  margin-left: 8px;

  &:hover {
    background-color: #1a3330;
  }
}

.custom-accordion-details {
  padding-top: 50px;
}

.toggle-switch-email {
  transform: scale(1);
  position: relative;
  width: 52px;
  margin-top: -3px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}

.toggle-switch-email-checkbox {
  display: none;
}

.toggle-switch-email-label {
  display: block;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  border: 0 solid #bbb;
}

.toggle-switch-inner-email {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.toggle-switch-inner-email:before,
.toggle-switch-inner-email:after {
  display: block;
  float: left;
  width: 50%;
  height: 25px;
  padding: 0;
  line-height: 28px;
  font-size: 12px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}

.toggle-switch-inner-email:before {
  content: 'ON';
  text-transform: uppercase;
  padding-left: 6px;
  padding-bottom: 6px;
  background-color: var(--primarycolor);
  color: #fff;
}

.toggle-switch-inner-email:after {
  content: 'OFF';
  text-transform: uppercase;
  padding-right: 5px;
  background-color: #DEE4E8!important;
  color: #5d676b;
  text-align: right;
}

.toggle-switch-switch-email {
  display: block;
  width: 19px;
  height: 18px;
  margin: 3px;
  background: #fff;
  position: absolute;
  top: 1px;
  bottom: 0;
  right: 28px;
  border: 0 solid #bbb;
  border-radius: 3px;
  transition: all 0.3s ease-in 0s;
}

.toggle-switch-email-checkbox:checked+.toggle-switch-email-label {
  .toggle-switch-inner-email {
    margin-left: 0;
  }

  .toggle-switch-switch-email {
    right: 0;
  }
}