.facebook_wrapper {
  width: 100%;
}

.close_image {
  background: none;
  border: none;
  transition: 0.3s all;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 15px;
  &:hover {
    opacity: 0.7;
  }
}

.nolink {
  font-family: Reader,sans-serif;
  font-weight: 400;
  font-size: 12px; 
  color: #6c737a;
  margin-top: 8px;
}

