@font-face {
  font-family: Reader;
  src: url(../../assets/fonts/reader-regular.otf);
}

.loading_wrapper {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0 auto;
  background: linear-gradient(180deg, #f7fbfe 0%, #f7fbfe 100%);
  padding-top: 50px;
  position: relative;
  padding-bottom: 130px;
}

.loaderr {
  position: fixed;
  right: 20px;
  z-index: 999;
  top: 20px;
  width: 333px;
  height: 90px;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.32);
  backdrop-filter: blur(2px);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  &_p {
    font-family: Reader, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin: 0px 14px;
  }
  &_close {
    cursor: pointer;
    transition: 0.3s all;
    &:hover {
      opacity: 0.8;
    }
  }
}

.loading_top {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &_left {
    display: flex;
    flex-direction: column;
  }
  &_right {
    display: flex;
    position: relative;
    &_big {
      box-shadow: 0px 0px 30px rgba(9, 118, 66, 0.15);
      border-radius: 20px;
    }
    &_free {
      position: absolute;
      left: -140px;
      top: 110px;
    }
    &_1 {
      position: absolute;
      top: -40px;
      left: 110px;
    }
    &_2 {
      position: absolute;
      right: -40px;
      top: 22%;
    }
    &_3 {
      position: absolute;
      bottom: -45px;
      right: 4%;
    }
    &_4 {
      position: absolute;
      bottom: -30px;
      left: 55%;
    }
  }
}

.loading_heading {
  font-family: Reader, sans-serif;
  font-weight: 700;
  font-size: 48px;
  color: #22262b;
}

.boost_p {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #22262b;
  line-height: 32px;
  margin-top: 30px;
}

.boost_p_2 {
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #22262b;
  margin-top: 30px;
}

.loading_middle {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  &_left {
    width: 560px;
    height: 399px;
    margin-right: 120px;
    img {
      width: 100%;
      height: 100%;
      filter: drop-shadow(0px 0px 30px rgba(9, 118, 66, 0.15));
    }
  }
  &_right {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.long_banner {
  width: 100%;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1d4842;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  font-family: Reader, sans-serif;
  font-weight: 400;
  font-size: 48px;
  color: #ffffff;
  height: 128px;
  cursor: pointer;
  position: relative;
}

.navigatebottom {
  cursor: pointer;
  position: absolute;
  bottom: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 193px;
  height: 74px;
  border: none;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.32);
  backdrop-filter: blur(2px);
  border-radius: 50px;
  font-family: Reader, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  transition: 0.3s all;
  &:hover {
    opacity: 0.6;
  }
}

.pricing {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 352px;
    height: 352px;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(9, 118, 66, 0.15);
    border-radius: 12px;
    &_top {
      display: flex;
      align-items: center;
      background: #e2f0fc;
      width: 100%;
      height: 94px;
      justify-content: center;
      &_text {
        font-family: Reader, sans-serif;
        font-weight: 700;
        font-size: 38px;
        color: #007be1;
      }
    }
    &_middle {
      width: 332px;
      background: #f0f3f4;
      border-radius: 15px;
      height: 140px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &_money {
        font-family: Reader, sans-serif;
        font-weight: 700;
        font-size: 60px;
        color: #3a5666;
      }
      &_small {
        font-family: Reader, sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: #3a5666;
        margin-top: 4px;
      }
    }
    &_bottom {
      width: 100%;
      margin-top: 20px;
      padding-top: 20px;
      padding-left: 50px;
      border-top: 1px solid #cce4f9;
      p {
        font-family: Reader, sans-serif;
        font-weight: 500;
        font-size: 18px;
        color: #22262b;
      }
    }
  }
}

.grey_bg {
  background-color: #a8b3b9;
}

.cream_bg {
  background-color: #dfe4de;
}

.empty_bg {
  background-color: #eff3f6;
}

.black {
  color: #22262b;
}

.greenp {
  color: #1d4842;
  font-weight: 700;
}

.loading_bottom {
  width: 100%;
  display: flex;
  margin-top: 100px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  &_left {
    width: 560px;
    height: 399px;
    img {
      width: 100%;
      height: 100%;
      filter: drop-shadow(0px 0px 30px rgba(9, 118, 66, 0.15));
    }
  }
  &_right {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.banner_end {
  width: 100%;
  height: 228px;
  background: #1d4842;
  border-radius: 20px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &_big {
    font-family: Reader, sans-serif;
    font-weight: 400;
    font-size: 48px;
    color: #ffffff;
  }
  &_small {
    font-family: 'Reader', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
    inline-size: 75%;
  }
}

.starttrial {
  width: 134px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 10px;
  outline: none;
  border: none;
  font-family: Reader, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #1d4842;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    opacity: 0.9;
  }
}

.starttrial_disabled {
  width: 134px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 10px;
  outline: none;
  border: none;
  font-family: Reader, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #9fa6ad;
  transition: 0.3s all;
}

.card_green {
  background-color: #1d4842;
  color: #ffffff;
}

.contact {
  font-size: 40px;
}

.pricing_banner {
  width: 100%;
  background-color: #1d4842;
  color: #ffffff;
  height: 70px;
  border-radius: 10px;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    font-family: 'Reader';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    padding: 5px 30px;
    color: #ffffff;
  }
}

.only {
  font-family: 'Reader';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  padding: 20px 0px;
}

.line_info {
  width: 100%;
  display: flex;

  .long {
    width: 530px;
  }
  div {
    margin-right: 20px;
    background-color: #1d4842;
    border-radius: 10px;
    width: 182px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-family: 'Reader';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: #ffffff;
      padding: 5px 0px;
    }
  }
}

.line_info2 {
  width: 100%;
  display: flex;
  margin-top: 20px;

  .long {
    width: 530px;
  }

  div {
    margin-right: 20px;
    border-radius: 10px;
    width: 182px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-family: 'Reader';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      padding: 5px 0px;
    }
  }
}

.secondtype {
  margin-top: 20px;
  width: 100%;
  display: flex;
  &_div1 {
    width: 540px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 146px;
    p {
      color: #1d4842;
      font-family: 'Reader';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
    }
  }
  &_div2 {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &_line {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      padding-left: 10px;
      justify-content: space-between;
      &_div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 182px;
        height: 42px;
        margin-right: 17px;
        border-radius: 10px;
        p {
          font-family: 'Reader';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }
}

.secondtype2 {
  margin-top: 20px;
  width: 100%;
  display: flex;
  &_div1 {
    width: 540px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 198px;
    p {
      color: #1d4842;
      font-family: 'Reader';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
    }
  }
  &_div2 {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &_line {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      padding-left: 10px;
      justify-content: space-between;
      &_div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 182px;
        height: 42px;
        margin-right: 17px;
        border-radius: 10px;
        p {
          font-family: 'Reader';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }
}

.disabled_na {
  p {
    color: #c8cccb;
  }
}
