.footer {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    p {
      color: var(--primarycolor);
      font-size: 12px;
    }
    li {
      font-size: 13px;
      color: var(--primarycolor);
      margin: 0px 18px;
      opacity: 0.8;
      transition: 0.4s all;
      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}

.reserved {
  font-size: 13px;
  color: #999999;
  margin-top: 12px;
}
